import(/* webpackMode: "eager", webpackExports: ["default"] */ "/code/apps/web/src/components/AppHeaderAvatarAuthenticated.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/code/apps/web/src/components/AppHeaderAvatarUnauthenticated.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/code/apps/web/src/components/FetchRecipeButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/code/apps/web/src/components/LandingPageSearchBox.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/code/apps/web/src/components/NewRecipeButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/code/node_modules/.pnpm/@mui+material@5.14.17_@emotion+react@11.11.1_@types+react@18.2.33_react@18.2.0__@emotion+styl_zwx34dz5imme3r26ud6x5xuhfi/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/code/node_modules/.pnpm/@mui+material@5.14.17_@emotion+react@11.11.1_@types+react@18.2.33_react@18.2.0__@emotion+styl_zwx34dz5imme3r26ud6x5xuhfi/node_modules/@mui/material/Stack/index.js");
;
import(/* webpackMode: "eager" */ "/code/node_modules/.pnpm/@mui+material@5.14.17_@emotion+react@11.11.1_@types+react@18.2.33_react@18.2.0__@emotion+styl_zwx34dz5imme3r26ud6x5xuhfi/node_modules/@mui/material/Toolbar/index.js");
;
import(/* webpackMode: "eager" */ "/code/node_modules/.pnpm/@mui+material@5.14.17_@emotion+react@11.11.1_@types+react@18.2.33_react@18.2.0__@emotion+styl_zwx34dz5imme3r26ud6x5xuhfi/node_modules/@mui/material/Tooltip/index.js");
;
import(/* webpackMode: "eager" */ "/code/node_modules/.pnpm/next@14.2.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/link.js");
